import styled from 'styled-components';

const StyledCustomersPage = styled.section`
  flex-grow: 1;
  padding: 0 72px 0 44px;
  margin-top: 32px;
  margin-bottom: 48px;

  h1 {
    letter-spacing: -0.04em;
    margin-bottom: 28px;
  }

  .customers {
  }

  .search > input::placeholder {
    font-weight: 500;
  }

  .image img {
    border-radius: 50%;
  }

  .actions {
    width: 96px;
  }

  .button {
    cursor: pointer;
    padding: 9px 16px;
    color: var(--grey-700);
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.25rem;
    border-radius: 8px;
    background: #ffffff;
    border: 1px solid var(--grey-300);
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  }
`;

export default StyledCustomersPage;
