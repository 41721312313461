import React, { useState, useEffect } from 'react';
import { DateTime } from 'luxon';
import type { ColumnsType } from 'antd/es/table';
import VPTable from 'src/components/VPTable';
import FlexibleDiv from 'src/components/FlexableDiv';
import { Text } from 'src/components/Typography';
import VPRangePicker from 'src/components/VPRangePicker';
import { ActivityPayload, getActivityLogs } from 'src/network/activity';
import { useAuth } from 'src/context/Auth';
import toastError from 'src/utils/toastError';
import { getAgents } from 'src/network/team';
import TablePaginationItem from 'src/utils/pagination';
import StyledActivityLogs from './styled';
import { IActivityLogs } from 'src/types/activity';
import Select from 'src/components/Select';
import { Calendar, Chevron } from 'src/assets/svg';
import { Member } from 'src/types/team';

const ActivityLogs = () => {
  const userContext = useAuth();

  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(1);
  const [userFilter, setUserFilter] = useState('');
  const [dateRange, setDateRange] = useState<[string, string]>(['', '']);

  const [agentsList, setAgentsList] = useState<{ value: number; label: string }[]>([]);

  const [activities, setActivities] = useState<IActivityLogs[]>([]);

  const columns: ColumnsType<IActivityLogs> = [
    {
      title: 'Activity',
      className: 'activity',
      render: (text, record) => (
        <>
          <span>
            {record.userId.firstname} {record.userId.lastname}
          </span>
          {record.description.replace(`${record.userId.firstname} ${record.userId.lastname}`, '')}
        </>
      )
    },
    {
      title: 'Date',
      dataIndex: 'date',
      render: (value, record) => DateTime.fromISO(record.createdAt).toFormat('LLL d, yyyy hh:mm a')
    }
  ];

  useEffect(() => {
    const fetchActivityLogs = async (payload: ActivityPayload) => {
      setLoading(true);
      try {
        const res = await getActivityLogs(payload);
        setActivities(res.activityLogs);
        setCurrentPage(res.current);
        setTotal(res.total);
      } catch (error) {
        toastError(error);
      } finally {
        setLoading(false);
      }
    };

    if (dateRange[0] && dateRange[1]) {
      fetchActivityLogs({
        page: currentPage,
        perPage: 20,
        userId: userFilter,
        from: dateRange?.[0],
        to: dateRange?.[1]
      });
    } else {
      fetchActivityLogs({
        page: currentPage,
        perPage: 20,
        userId: userFilter
      });
    }
  }, [currentPage, userFilter, dateRange]);

  useEffect(() => {
    (async () => {
      try {
        const res = await getAgents(
          Number(userContext?.user?.businessId || userContext?.user?.id) as number,
          {}
        );
        setAgentsList(
          res.agents.map((agent: Member) => ({
            value: agent.id as number,
            label: `${agent.firstName} ${agent.lastName}`
          }))
        );
      } catch (error) {
        toastError(error);
      }
    })();
  }, []);

  return (
    <StyledActivityLogs>
      <Text variant="xs" color="var(--grey-500)" mb="8px">
        Filter by
      </Text>
      <FlexibleDiv alignItems="center" mb="24px" flexGap="14px" className="filter-FlexibleDiv">
        <Select
          sm
          className="user-filter"
          suffixIcon={<Chevron />}
          value={userFilter}
          options={[{ label: 'All', value: '' }, ...agentsList]}
          onChange={(val: any) => setUserFilter(val)}
        />
        <VPRangePicker
          value={[
            dateRange[0] ? DateTime.fromISO(dateRange[0]) : null,
            dateRange[1] ? DateTime.fromISO(dateRange[1]) : null
          ]}
          ranges={{
            Today: [DateTime.now(), DateTime.now()],
            'This Week': [
              DateTime.now().startOf('week').startOf('day'),
              DateTime.now().endOf('week').startOf('day')
            ],
            'This Month': [
              DateTime.now().startOf('month').startOf('day'),
              DateTime.now().endOf('month').startOf('day')
            ]
          }}
          onChange={(dates) => {
            if (dates === null) {
              setDateRange(['', '']);
            } else {
              // @ts-ignore
              setDateRange([dates[0].startOf('day').toISO(), dates[1].startOf('day').toISO()]);
            }
          }}
        />
      </FlexibleDiv>
      <VPTable
        noSelect
        removeTop={false}
        loading={loading}
        columns={columns as ColumnsType<object>}
        dataSource={activities}
        pagination={TablePaginationItem(total, 20, setCurrentPage)}
      />
    </StyledActivityLogs>
  );
};

export default ActivityLogs;
