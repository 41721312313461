import { useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { message } from 'antd';
import ResetPasswordPage from './routes/ResetPasswordPage';
import ForgotPasswordPage from './routes/ForgotPasswordPage';
import SignInPage from './routes/SignInPage';
import SignUpPage from './routes/SignUpPage';
import DashboardPage from './routes/DashboardPage';
import SettingsPage from './routes/SettingsPage';
import CustomersPage from './routes/CustomersPage';
import AllSettingsPage from './routes/SettingsPage/AllSettingsPage';

import ConversationsPage from './routes/ConversationsPage';
import AuthProvider, { useAuth } from './context/Auth';
import IndexPage from './routes';
import { SocketContextProvider } from './context/socketContext';
import AddRolePage from './routes/SettingsPage/AddRolePage';
import BroadcastPage from './routes/BroadcastPage';
import ViewRolePage from './routes/SettingsPage/ViewRolePage';
import { checkNotificationPromise } from './utils/app';

function App() {
  const userContext = useAuth();

  const ROUTES = [
    { path: '/signin', element: SignInPage },
    { path: '/signup', element: SignUpPage },
    { path: '/forgot-password', element: ForgotPasswordPage },
    { path: '/reset-password', element: ResetPasswordPage }
  ];

  const PROTECTEDROUTES = [
    { path: '/dashboard', element: <DashboardPage /> },
    { path: '/conversations', element: <ConversationsPage /> },
    { path: '/customers', element: <CustomersPage /> },
    {
      path: '/broadcast',
      element: <BroadcastPage />
    },
    // {
    //   path: "/templates",
    //   element: <TemplatesPage />,
    //   children: [
    //     { path: "", element: <AllTemplates /> },
    //     { path: "create", element: <CreateTemplates /> },
    //   ],
    // },
    // {
    //   path: "/products",
    //   element: <ProductsPage />,
    //   children: [
    //     { path: "", element: <AllProducts /> },
    //     { path: "add", element: <AddProducts /> },
    //   ],
    // },
    // { path: "/earnings", element: <EarningsPage /> },
    {
      path: '/settings',
      element: <SettingsPage />,
      children: [
        { path: '', element: <AllSettingsPage /> },
        { path: 'add-role', element: <AddRolePage /> },
        { path: 'role/:roleId', element: <ViewRolePage /> }
        // { path: "diagflow-cx", element: <DiagflowCXPage /> },
        // { path: "diagflow-es", element: <DiagflowESPage /> },
      ]
    }
  ];

  message.config({ maxCount: 1 });

  useEffect(() => {
    // Let's check if the browser supports notifications
    if (!('Notification' in window)) {
      console.log('This browser does not support notifications.');
    } else if (checkNotificationPromise()) {
      Notification.requestPermission().then();
    } else {
      Notification.requestPermission();
    }
  }, []);

  return (
    <BrowserRouter>
      <AuthProvider>
        <SocketContextProvider>
          <Routes>
            <Route path="/" element={<IndexPage />} />
            {ROUTES.map((elem) => (
              <Route key={elem.path} path={elem.path} element={<elem.element />} />
            ))}
            {PROTECTEDROUTES.map((elem) => {
              if (elem.children) {
                return (
                  <Route path={elem.path} key={elem.path} element={elem.element}>
                    {elem.children.map((child: any) => (
                      <Route key={child.path} path={child.path} element={child.element} />
                    ))}
                  </Route>
                );
              }
              return <Route key={elem.path} path={elem.path} element={elem.element} />;
            })}
          </Routes>
        </SocketContextProvider>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
