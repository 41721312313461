import { useState } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { Form } from 'antd';
import { useAuth } from 'src/context/Auth';
import AuthScreensLayout from 'src/layout/AuthScreensLayout';
import FlexibleDiv from 'src/components/FlexableDiv';
import Button from 'src/components/Button';
import Select, { Option } from 'src/components/Select';
import { Display, Text } from 'src/components/Typography';
import { FormItem, FormInput } from 'src/components/Form';
import { signupUser } from 'src/network/auth';
import { toastErrorUnauthScreens } from 'src/utils/toastError';
import { SignUp } from 'src/types/auth';
import styles from './SignUpPage.module.css';
import { Chevron } from 'src/assets/svg';

const SignUpPage = () => {
  const navigate = useNavigate();
  const userContext = useAuth();
  const [userSignedUp, setUserSignedUp] = useState(false);
  const [email, setEmail] = useState('');

  if (localStorage.getItem('VP_TOKEN')) {
    return <Navigate to="/dashboard" />;
  }

  const onFinish = async (values: SignUp) => {
    try {
      const res = await signupUser(values);
      setEmail(values.email);
      setUserSignedUp(true);
    } catch (error) {
      toastErrorUnauthScreens(error);
    }
  };

  return (
    <AuthScreensLayout>
      {userSignedUp ? (
        <>
          <Display as="h1" variant="md" weight="bold" mb="36px" className={styles.title}>
            Confirm your mailbox
          </Display>
          <Text variant="md" color="var(--grey-500)" mb="40px">
            An email verification link has been sent to{' '}
            <Text as="span" variant="md" color="var(--primary-700)">
              {email}
            </Text>
            , click on the link to verify your email and then proceed to create your password
          </Text>
          <Button fullWidth onClick={() => navigate(0)}>
            Go Back
          </Button>
        </>
      ) : (
        <>
          <Display as="h1" variant="md" weight="bold" mb="36px" className={styles.title}>
            Start integrating your channels
          </Display>
          <Form
            name="signUp"
            autoComplete="off"
            layout="vertical"
            requiredMark={false}
            onFinish={onFinish}>
            <FlexibleDiv flexGap="28px" wrap="wrap" className={styles.name}>
              <FormItem
                mb="28px"
                name="firstname"
                className={styles.firstName}
                label={
                  <Text as="span" variant="sm" weight="medium" color="var(--grey-700)">
                    First name
                  </Text>
                }
                rules={[{ required: true, message: 'This field is required' }]}>
                <FormInput width="100%" />
              </FormItem>
              <FormItem
                mb="28px"
                name="lastname"
                className={styles.lastName}
                label={
                  <Text as="span" variant="sm" weight="medium" color="var(--grey-700)">
                    Last name
                  </Text>
                }
                rules={[{ required: true, message: 'This field is required' }]}>
                <FormInput width="100%" />
              </FormItem>
            </FlexibleDiv>
            <FormItem
              mb="28px"
              name="email"
              label={
                <Text as="span" variant="sm" weight="medium" color="var(--grey-700)">
                  Work email
                </Text>
              }
              rules={[
                { required: true, message: 'This field is required' },
                { type: 'email', message: 'Please enter a valid email address' }
              ]}>
              <FormInput type="email" width="100%" placeholder="Enter your work email" />
            </FormItem>
            <FormItem
              mb="36px"
              name="role"
              label={
                <Text as="span" variant="sm" weight="medium" color="var(--grey-700)">
                  What is your role
                </Text>
              }
              rules={[{ required: true, message: 'This field is required' }]}>
              <Select placeholder="Select your company role" suffixIcon={<Chevron />}>
                <Option value="ceo">CEO</Option>
                <Option value="coo">COO</Option>
                <Option value="product-manager">Product Manager</Option>
                <Option value="project-manager">Project Manager</Option>
                <Option value="customer-service">Customer service</Option>
                <Option value="developer">Developer</Option>
                <Option value="sales">Sales / marketing professional</Option>
                <Option value="other">Other</Option>
              </Select>
            </FormItem>
            <Button htmlType="submit" fullWidth mb="28px">
              Sign up
            </Button>
          </Form>
          <Text as={Link} to="/signin" variant="sm" color="var(--primary-500)" underline>
            Sign into existing account
          </Text>
        </>
      )}
    </AuthScreensLayout>
  );
};

export default SignUpPage;
