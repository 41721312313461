import { AgentPayload, TeamRes, UpdateAgentPayload } from 'src/types/team';
import gatewayInstance from './axiosGateway';

export type GetAgentParams = {
  perPage?: number;
  page?: number;
};

export const createAgent = async (payload: AgentPayload) => {
  const { data } = await gatewayInstance.post('/user/create/agent', payload);
  return data;
};

export const updateAgent = async (id: string, payload: UpdateAgentPayload) => {
  const { data } = await gatewayInstance.put(`/user/update/agent/${id}`, payload);
  return data;
};

export const deleteAgent = async (id: string) => {
  const { data } = await gatewayInstance.delete('/user/delete/agent/' + id);
  return data;
};

export const reactivateAgent = async (id: string) => {
  const { data } = await gatewayInstance.patch('/user/reactivate/' + id);
  return data;
};

export const getAgents = async (id: number, params: GetAgentParams) => {
  const { data } = await gatewayInstance.get(`/user/agents/${id}`, { params });

  return data.data as TeamRes;
};
