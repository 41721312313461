import { Link, Navigate, useNavigate } from 'react-router-dom';
import { Form } from 'antd';
import AuthScreensLayout from 'src/layout/AuthScreensLayout';
import Button from 'src/components/Button';
import FlexibleDiv from 'src/components/FlexableDiv';
import { FormItem, FormInput } from 'src/components/Form';
import { Display, Text } from 'src/components/Typography';
import { toastErrorUnauthScreens } from 'src/utils/toastError';
import { useAuth } from 'src/context/Auth';
import { SignIn } from 'src/types/auth';
import { signinUser } from 'src/network/auth';
import { getUser } from 'src/network/profile';

const SignInPage = () => {
  const navigate = useNavigate();
  const userContext = useAuth();

  if (localStorage.getItem('VP_TOKEN')) {
    return <Navigate to="/dashboard" />;
  }

  const onFinish = async (values: SignIn) => {
    try {
      const res = await signinUser(values);
      localStorage.setItem('VP_TOKEN', res.data.token);
      localStorage.setItem('VP_PERMISSIONS', JSON.stringify(res.data.rolePermissions));
      const userRes = await getUser(res.data.id);
      userContext?.setUser({ ...userRes.data, rolePermissions: res.data.rolePermissions });
      localStorage.setItem('VP_USER', JSON.stringify(userRes.data));
      navigate('/dashboard');
    } catch (error) {
      toastErrorUnauthScreens(error);
    }
  };

  return (
    <AuthScreensLayout>
      <Display as="h1" variant="md" weight="bold" mb="36px">
        Ahoy! Welcome back
      </Display>
      <Form
        name="signIn"
        autoComplete="off"
        layout="vertical"
        requiredMark={false}
        onFinish={onFinish}>
        <FormItem
          mb="28px"
          name="email"
          label={
            <Text as="span" variant="sm" weight="medium" color="var(--grey-700)">
              Email address
            </Text>
          }
          rules={[
            { required: true, message: 'This field is required' },
            { type: 'email', message: 'Please enter a valid email address' }
          ]}>
          <FormInput width="100%" type="email" />
        </FormItem>
        <FormItem
          mb="36px"
          name="password"
          label={
            <Text as="span" variant="sm" weight="medium" color="var(--grey-700)">
              Password
            </Text>
          }
          rules={[{ required: true, message: 'This field is required' }]}>
          <FormInput width="100%" type="password" />
        </FormItem>
        <Button htmlType="submit" fullWidth mb="28px">
          Log in
        </Button>
        <FlexibleDiv justifyContent="space-between">
          {/* <Text as={Link} to="/signup" variant="sm" color="var(--primary-500)" underline>
            Don’t have an account?
          </Text> */}
          <Text as={Link} to="/forgot-password" variant="sm" color="var(--primary-500)" underline>
            Forgot your password?
          </Text>
        </FlexibleDiv>
      </Form>
    </AuthScreensLayout>
  );
};

export default SignInPage;
