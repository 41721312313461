import {
  conversationsParam,
  IAggregatedConversation,
  IMessage,
  newConversationsParam
} from 'src/types/conversation';
import gatewayInstance from './axiosGateway';

export const getAllConversations = async (
  payload: conversationsParam
): Promise<IAggregatedConversation[]> => {
  const { data } = await gatewayInstance.get('/conversation', {
    params: payload
  });

  return data;
};

export const newGetAllConversations = async (
  payload: newConversationsParam
): Promise<{ conversations: IAggregatedConversation[]; page: string; total: 503 }> => {
  const { data } = await gatewayInstance.get('/conversation/new', {
    params: payload
  });

  return data.data;
};
export const getSingleConversation = async (id: string | number): Promise<IMessage[]> => {
  const { data } = await gatewayInstance.get(`/conversation/message/${id}`);
  return data;
};

export const getAgentsforAssign = async (search: string) => {
  const { data } = await gatewayInstance.get(
    `/user/agents-with-conversation?queryString=${search}`
  );

  return data;
};

export const assignConvoToAgent = async (payload: { conversationId: string; agentId: number }) => {
  const { data } = await gatewayInstance.put(`/conversation/assign-conversation`, payload);

  return data;
};
