import React, { useContext } from 'react';
import { DateTime } from 'luxon';
import type { ColumnsType } from 'antd/es/table';
import { WalletDetailsContext } from 'src/routes/BroadcastPage';
import FlexibleDiv from 'src/components/FlexableDiv';
import { Text } from 'src/components/Typography';
import VPTable from 'src/components/VPTable';
import WalletsCard from './WalletsCard';
import TablePaginationItem from 'src/utils/pagination';
import { currencyFormatter } from 'src/utils/app';
import { TopUpHistory } from 'src/types/wallet';
import WalletStatus from './WalletsStatus';

type WalletProps = {
  total: number | undefined;
  setPage: (page: number) => void;
  tableLoading: boolean;
  wallets: TopUpHistory[];
};

const Wallets = ({ tableLoading, setPage, total, wallets }: WalletProps) => {
  const wallet = useContext(WalletDetailsContext);

  const columns: ColumnsType<TopUpHistory> = [
    {
      title: 'Transaction Ref',
      dataIndex: 'transactionReference'
    },
    {
      title: 'Amount',
      render: (value, record) => currencyFormatter.format(record.amount)
    },
    {
      title: 'Initated by',
      render: (value, record) => `${record.initiatedBy.firstName} ${record.initiatedBy.lastName}`
    },
    {
      title: 'Status',
      render: (value, record) => <WalletStatus status={record.status} />
    },
    {
      title: 'Date',
      render: (value, record) => DateTime.fromISO(record.createdAt).toFormat('LLL d, yyyy hh:mm a')
    }
  ];

  return (
    <>
      <FlexibleDiv
        flexGap="24px"
        justifyContent="space-between"
        wrap="wrap"
        alignItems="stretch"
        mb="40px">
        <WalletsCard
          title="CURRENT BALANCE"
          value={currencyFormatter.format(wallet?.walletDetails?.walletBalance! | 0)}
        />
        <WalletsCard title="MESSAGES SENT" value={wallet?.walletDetails?.totalMessagesSent! | 0} />
        <WalletsCard
          title="EST. WHATSAPP BROADCAST MESSAGES LEFT"
          value={wallet?.walletDetails?.whatsappSessionsLeft! | 0}
        />
      </FlexibleDiv>
      <Text variant="lg" weight="medium" mb="16px">
        Top-up history
      </Text>
      <VPTable
        noSelect
        loading={tableLoading}
        // @ts-ignore
        columns={columns}
        dataSource={wallets}
        pagination={TablePaginationItem(total, 20, setPage)}
      />
    </>
  );
};

export default Wallets;
