import styled from 'styled-components';
import { Select as Slct } from 'antd';

type SelectProps = {
  sm?: boolean;
  // height?: string;
  // width?: string;
};

const Select = styled(Slct)<SelectProps>`
  &:not(.ant-select-customize-input) .ant-select-selector {
    height: ${({ sm }) => (sm ? '36px' : '44px')};
    /* width: ${({ sm }) => sm && '120px'}; */
    border-radius: 8px;
    padding: ${({ sm }) => (sm ? '8px 12px' : '6px 14px')};
    background-color: #fff;
    border: 1px solid var(--grey-300);
  }

  &:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: var(--grey-300);
  }

  &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    box-shadow: none;
    border-color: var(--primary-500);
  }

  &.ant-select-single.ant-select-show-arrow .ant-select-selection-item {
    color: ${({ sm }) => sm && 'var(--grey-500)'};
    font-weight: ${({ sm }) => sm && 500};
    font-size: ${({ sm }) => sm && '14px'};
    line-height: ${({ sm }) => sm && '14px'};
    letter-spacing: ${({ sm }) => sm && '-0.03em'};
  }
`;

const Option = styled(Slct.Option)``;

export { Select as default, Option };
