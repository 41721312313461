import React from 'react';
import FlexibleDiv from '../FlexableDiv';
import { Text } from '../Typography';
import DeleteModalStyles from './styled';

type DeleteModalProps = {
  title: string;
  name: string;
  onCancelClick: () => void;
  onDeleteClick: () => void;
};

const DeleteModal = ({ title, name, onCancelClick, onDeleteClick }: DeleteModalProps) => {
  return (
    <DeleteModalStyles>
      <Text as="h1" mb="8px" variant="lg" weight="medium">
        Delete {title}
      </Text>
      <Text variant="md" className="confirmation-text">
        Are you sure you want to delete <span>{name}</span>? This action cannot be reversed
      </Text>
      <FlexibleDiv flexGap="12px" alignItems="center">
        <button className="btn cancel" onClick={onCancelClick}>
          Cancel
        </button>
        <button className="btn delete" onClick={onDeleteClick}>
          Delete
        </button>
      </FlexibleDiv>
    </DeleteModalStyles>
  );
};

export default DeleteModal;
