import React from 'react';
import { AgentWithConvo } from 'src/types/conversation';
import styled from 'styled-components';
import FlexibleDiv from '../FlexableDiv';

const StyledAssignList = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 6px;
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 8px;
  }

  .dp {
    display: grid;
    place-content: center;
    width: 44px;
    height: 44px;
    background: #f4f2ff;
    border-radius: 50%;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5rem;
    text-align: center;
    color: #6f4eff;
  }

  p {
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #344054;
    margin-bottom: 0;
  }

  span {
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #667085;
    margin-bottom: 0;
  }

  &.active-agent {
    background: #efebff;
    border-radius: 6px;
    position: relative;
  }

  .check {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
  }
`;

type IAssignList = {
  item: AgentWithConvo;
  onClick: () => void;
  selectedAgent?: number;
};

const AssignList = ({ item, onClick, selectedAgent }: IAssignList) => {
  return (
    <StyledAssignList onClick={onClick} className={item.id === selectedAgent ? 'active-agent' : ''}>
      <div className="dp">{`${item.firstName.charAt(0)}${item.lastName.charAt(0)}`}</div>
      <div>
        <p>{`${item.firstName} ${item.lastName}`}</p>
        <span>
          {item._count.conversations} conversation{item._count.conversations > 1 ? 's' : ''}
        </span>
      </div>
      {item.id === selectedAgent && (
        <svg
          width="18"
          height="13"
          viewBox="0 0 18 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="check">
          <path
            d="M17 1L6 12L1 7"
            stroke="#6F4EFF"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      )}
    </StyledAssignList>
  );
};

export default AssignList;
