import React, { useEffect, useState } from 'react';
import { Navigate, useLocation, useSearchParams } from 'react-router-dom';
import Profile from 'src/components/SettingsTabs/Profile';
import Team from 'src/components/SettingsTabs/Team';
import ActivityLogs from 'src/components/SettingsTabs/ActivityLogs';
import Plan from 'src/components/SettingsTabs/Plan';
import Billing from 'src/components/SettingsTabs/Billing';
import BankAccount from 'src/components/SettingsTabs/BankAccount';
import Integrations from 'src/components/SettingsTabs/Integrations';
import Automations from 'src/components/SettingsTabs/Automations';
import AccountSettings from 'src/components/SettingsTabs/AccountSettings';
import FlexibleDiv from 'src/components/FlexableDiv';
import Roles from 'src/components/SettingsTabs/Roles';
import { Display } from 'src/components/Typography';
import StyledSettings from './styled';
import { useAuth } from 'src/context/Auth';
import QuickReplies from 'src/components/SettingsTabs/QuickReplies';

const AllSettingsPage = () => {
  const location = useLocation();
  const userContext = useAuth();
  let [searchParams, setSearchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState(searchParams.get('tab') || location.state || 'team');

  const TABS: any = [];
  if (userContext?.user?.rolePermissions?.can_view_team_members) {
    TABS.push({ title: 'Team', name: 'team', component: Team });
  }
  if (userContext?.user?.rolePermissions?.can_view_roles) {
    TABS.push({ title: 'Roles', name: 'roles', component: Roles });
  }
  TABS.push({ title: 'Quick Replies', name: 'quick-replies', component: QuickReplies });
  if (userContext?.user?.rolePermissions?.can_view_activity_logs) {
    TABS.push({ title: 'Activity', name: 'activity', component: ActivityLogs });
  }

  useEffect(() => {
    if (TABS.length > 0) {
      setActiveTab(searchParams.get('tab') || (TABS[0].name as string));
    }
  }, [TABS]);

  // if (
  //   !userContext?.user?.rolePermissions?.can_view_roles &&
  //   !userContext?.user?.rolePermissions?.can_view_team_members
  // ) {
  //   return <Navigate to="/dashboard" />;
  // }

  // const TABS = [
  // { name: 'Profile', component: Profile },
  //   { title: 'Team', name: 'team', component: Team },
  //   { title: 'Roles', name: 'roles', component: Roles }
  // { name: "Plan", component: Plan },
  // { name: "Billing", component: Billing },
  // { name: "Bank account", component: BankAccount },
  // { name: 'Integrations', component: Integrations },
  // { name: "Automations", component: Automations },
  // { name: "Account settings", component: AccountSettings },
  // ];

  return (
    <StyledSettings>
      <Display as="h1" variant="xs" weight="medium">
        Settings
      </Display>
      <FlexibleDiv as="nav" flexGap="20px" alignItems="center" mb="24px">
        {TABS.map((tab: any) => {
          if (tab && tab.title && tab.name) {
            return (
              <div
                key={tab.name}
                onClick={() => {
                  setSearchParams({ tab: tab.name });
                  setActiveTab(tab.name);
                }}
                className={`tab-item ${activeTab === tab.name ? 'active' : ''}`}>
                {tab.title}
              </div>
            );
          }
        })}
      </FlexibleDiv>
      {TABS.map((tab: any) => tab && tab.name === activeTab && <tab.component key={tab.name} />)}
    </StyledSettings>
  );
};

export default AllSettingsPage;
