import React, { useCallback, useEffect, useState } from 'react';
import { Form } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import useDebounce from 'src/hooks/useDebounce';
import FlexibleDiv from 'src/components/FlexableDiv';
import Button from 'src/components/Button';
import VPTable from 'src/components/VPTable';
import VPModal from 'src/components/VPModal';
import TablePaginationItem from 'src/utils/pagination';
import { Text } from 'src/components/Typography';
import { FormInput, FormItem } from 'src/components/Form';
import FormTextArea from 'src/components/Form/TextArea';
import {
  CreateQuickReplyPayload,
  GetQuickRepliesPayload,
  createQuickReply,
  deleteQuickReply,
  editQuickReply,
  getQuickReplies
} from 'src/network/quickReplies';
import toastError from 'src/utils/toastError';
import toast from 'src/utils/toasts';
import { IQuickReply } from 'src/types/quickReplies';
import StyledQuickReplies from './styled';
import { Edit, Trash } from 'src/assets/svg';
import DeleteModal from 'src/components/DeleteModal';

const QuickReplies = () => {
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(1);
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 500);
  const [quickReplies, setQuickReplies] = useState<IQuickReply[]>([]);

  const [addQuickRepliesForm] = Form.useForm();
  const [editQuickRepliesForm] = Form.useForm();
  const [actionKey, setActionKey] = useState<IQuickReply>();

  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isBtnSaving, setIsBtnSaving] = useState(false);

  const columns: ColumnsType<IQuickReply> = [
    {
      title: 'Name',
      dataIndex: 'name'
    },
    {
      title: 'Messages',
      dataIndex: 'message'
    },
    {
      title: '',
      className: 'actions',
      render: (text, record, index) => {
        return (
          <FlexibleDiv flexGap="8px" alignItems="center" justifyContent="flex-end">
            <div
              className="action"
              onClick={() => {
                setShowDeleteModal(true);
                setActionKey(record);
              }}>
              <Trash />
            </div>
            <div
              className="action"
              onClick={() => {
                editQuickRepliesForm.setFieldsValue({
                  name: record.name,
                  message: record.message
                });
                setIsEditModalOpen(true);
                setActionKey(record);
              }}>
              <Edit />
            </div>
          </FlexibleDiv>
        );
      }
    }
  ];

  const fetchQuickReplies = useCallback(async (payload: GetQuickRepliesPayload) => {
    setLoading(true);
    try {
      const res = await getQuickReplies(payload);
      setCurrentPage(res.current);
      setTotal(res.total);
      setQuickReplies(res.data);
    } catch (error) {
      toastError(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchQuickReplies({ page: currentPage, perPage: 10, search: debouncedSearch.trim() });
  }, [currentPage, debouncedSearch, fetchQuickReplies]);

  useEffect(() => {
    addQuickRepliesForm.resetFields();
  }, [isAddModalOpen]);

  const submitAddQuickRepliesForm = async (values: CreateQuickReplyPayload) => {
    setIsBtnSaving(true);
    try {
      const res = await createQuickReply(values);
      fetchQuickReplies({ page: currentPage, perPage: 10, search: debouncedSearch.trim() });
      setIsAddModalOpen(false);
      toast('success', 'Quick reply added successfully');
    } catch (error) {
      // @ts-ignore
      toast('error', error.response.data.message || 'Something went wrong');
    } finally {
      setIsBtnSaving(false);
    }
  };

  const submitEditQuickRepliesForm = async (values: CreateQuickReplyPayload) => {
    setIsBtnSaving(true);
    try {
      const res = await editQuickReply(actionKey?.id!, values);
      fetchQuickReplies({ page: currentPage, perPage: 10, search: debouncedSearch.trim() });
      setIsEditModalOpen(false);
      toast('success', 'Quick reply edited successfully');
    } catch (error) {
      toastError(error);
    } finally {
      setIsBtnSaving(false);
    }
  };

  return (
    <StyledQuickReplies>
      <div>
        <Text as="h2" variant="lg" weight="medium" mb="0">
          Quick replies
        </Text>
        <Text variant="sm" color="var(--grey-500)" mb="24px">
          Qucik replies are predefined responses used to quickly reply common questions
        </Text>
      </div>
      <FlexibleDiv justifyContent="space-between" alignItems="center" className="top">
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}>
          <FormInput
            height="36px"
            width="360px"
            placeholder="Search..."
            className="search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </form>
        <Button className="new-reply" onClick={() => setIsAddModalOpen(true)}>
          New quick reply
        </Button>
      </FlexibleDiv>
      <VPTable
        noSelect
        removeTop
        loading={loading}
        dataSource={quickReplies}
        columns={columns as ColumnsType<object>}
        pagination={TablePaginationItem(total, 10, setCurrentPage)}
      />
      <VPModal
        centered
        closable={false}
        className="delete-modal"
        visible={showDeleteModal}
        footer={<></>}>
        <DeleteModal
          title="quick reply"
          name={`${actionKey?.name}`}
          onCancelClick={() => setShowDeleteModal(false)}
          onDeleteClick={() => {
            (async () => {
              try {
                await deleteQuickReply(actionKey?.id!);
                fetchQuickReplies({
                  page: currentPage,
                  perPage: 10,
                  search: debouncedSearch.trim()
                });
                setShowDeleteModal(false);
                toast('success', 'Quick reply deleted successfully');
              } catch (error) {
                toastError(error);
              }
            })();
          }}
        />
      </VPModal>
      <VPModal
        centered
        closable={false}
        className="form-modal"
        visible={isAddModalOpen}
        footer={<></>}>
        <Text variant="lg" weight="medium" mb="20px">
          Add new quick reply
        </Text>
        <Form
          name="addQuickReply"
          autoComplete="off"
          layout="vertical"
          requiredMark={false}
          form={addQuickRepliesForm}
          onFinish={submitAddQuickRepliesForm}>
          <FormItem
            mb="24px"
            name="name"
            label={
              <div>
                <Text mb="0" variant="sm" weight="medium" color="var(--grey-700)">
                  Name of quick reply
                </Text>
                <Text mb="0" variant="sm" color="var(--grey-500)">
                  Use a name that you and your agents can easily recognize
                </Text>
              </div>
            }
            rules={[
              { required: true, message: 'This field is required' },
              { max: 24, message: '' }
            ]}>
            <FormInput placeholder="e.g telephone banking" />
          </FormItem>
          <FormItem
            mb="24px"
            name="message"
            label={
              <Text as="span" variant="sm" weight="medium" color="var(--grey-700)">
                Message
              </Text>
            }
            rules={[{ required: true, message: 'This field is required' }]}>
            <FormInput
              as={FormTextArea}
              placeholder="Enter your response"
              autoSize={{ minRows: 6, maxRows: 7 }}
            />
          </FormItem>
          <FlexibleDiv flexGap="12px" alignItems="center">
            <button className="btn cancel" onClick={() => setIsAddModalOpen(false)}>
              Cancel
            </button>
            <Button htmlType="submit" className="btn submit" loading={isBtnSaving}>
              Done
            </Button>
          </FlexibleDiv>
        </Form>
      </VPModal>
      <VPModal
        centered
        closable={false}
        className="form-modal"
        visible={isEditModalOpen}
        footer={<></>}>
        <Text variant="lg" weight="medium" mb="20px">
          Edit quick reply
        </Text>
        <Form
          name="addQuickReply"
          autoComplete="off"
          layout="vertical"
          requiredMark={false}
          form={editQuickRepliesForm}
          onFinish={submitEditQuickRepliesForm}>
          <FormItem
            mb="24px"
            name="name"
            label={
              <div>
                <Text mb="0" variant="sm" weight="medium" color="var(--grey-700)">
                  Name of quick reply
                </Text>
                <Text mb="0" variant="sm" color="var(--grey-500)">
                  Use a name that you and your agents can easily recognize
                </Text>
              </div>
            }
            rules={[
              { required: true, message: 'This field is required' },
              { max: 24, message: '' }
            ]}>
            <FormInput placeholder="e.g telephone banking" />
          </FormItem>
          <FormItem
            mb="24px"
            name="message"
            label={
              <Text as="span" variant="sm" weight="medium" color="var(--grey-700)">
                Message
              </Text>
            }
            rules={[{ required: true, message: 'This field is required' }]}>
            <FormInput
              as={FormTextArea}
              placeholder="Enter your response"
              autoSize={{ minRows: 6, maxRows: 7 }}
            />
          </FormItem>
          <FlexibleDiv flexGap="12px" alignItems="center">
            <button
              className="btn cancel"
              onClick={() => {
                setActionKey(undefined);
                editQuickRepliesForm.resetFields();
                setIsEditModalOpen(false);
              }}>
              Cancel
            </button>
            <Button htmlType="submit" className="btn submit" loading={isBtnSaving}>
              Done
            </Button>
          </FlexibleDiv>
        </Form>
      </VPModal>
    </StyledQuickReplies>
  );
};

export default QuickReplies;
