export const checkFirstKey = (key: string): string => {
  switch (key) {
    case 'Conversation permissions':
      return 'conversationPermissions';
    case 'Assign chat permissions':
      return 'assignPermissions';
    case 'Customer permissions':
      return 'customerPermissions';
    case 'Team permissions':
      return 'teamPermissions';
    case 'Role permissions':
      return 'rolePermissions';
    case 'Activity log permissions':
      return 'activityPermissions';
    default:
      return '';
  }
};

export const checkSecondKey = (key: string): string => {
  switch (key) {
    case 'Can view conversations':
      return 'canView';
    case 'Can chat and share files with customers':
      return 'canChatShare';
    case 'Can view unassigned chats':
      return 'canViewAssigned';
    case 'Can assign chats to others':
      return 'canAssignAgents';
    case 'Can assign chats to self':
      return 'canAssignSelf';
    case 'Can view customers':
      return 'canViewCustomers';
    case 'Can update customer details':
      return 'canUpdate';
    case 'Can view team members':
      return 'canViewTeam';
    case 'Can add team members':
      return 'canAddTeam';
    case 'Can update team members details':
      return 'canUpdateTeam';
    case 'Can delete team members':
      return 'canDeleteTeam';
    case 'Can view roles':
      return 'canViewRole';
    case 'Can create roles':
      return 'canCreateRole';
    case 'Can update roles':
      return 'canUpdateRole';
    case 'Can view activity logs':
      return 'canViewActivityLogs';
    default:
      return '';
  }
};
