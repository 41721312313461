import styled from 'styled-components';

const MainConversationStyles = styled.section`
  display: flex;
  flex-grow: 1;

  .img-upload {
    position: relative;
    padding: 6px;
    display: inline-flex;
    gap: 6px;
    background: white;
    border-top-right-radius: 4px;
    border-top: 1px dashed var(--primary-400);
    border-right: 1px dashed var(--primary-400);

    span {
      cursor: pointer;
      padding: 0px 4px;
      display: inline-grid;
      position: absolute;
      right: 8px;
      top: 8px;
      border-radius: 50%;
      background: white;
    }
  }

  .video-upload {
    position: relative;
    padding: 6px;
    width: 256px;
    background: white;
    border-top-right-radius: 4px;
    border-top: 1px dashed var(--primary-400);
    border-right: 1px dashed var(--primary-400);

    span {
      cursor: pointer;
      padding: 0px 4px;
      display: inline-grid;
      position: absolute;
      right: 8px;
      top: 8px;
      border-radius: 50%;
      background: white;
    }
  }

  .doc-upload {
    position: relative;
    padding: 6px;
    width: 256px;
    background: white;
    border-top-right-radius: 4px;
    border-top: 1px dashed var(--primary-400);
    border-right: 1px dashed var(--primary-400);

    span {
      cursor: pointer;
      padding: 0px 4px;
      display: inline-grid;
      position: absolute;
      right: 8px;
      top: 8px;
      border-radius: 50%;
      background: white;
    }
  }

  .deleted {
    color: var(--grey-500);
    font-style: italic;

    &.outbound {
      color: var(--grey-300) !important;
    }
  }

  .ant-input[disabled] {
    background-color: #fff;
  }

  main {
    flex-grow: 1;
  }

  .header {
    position: sticky;
    top: 0;
    background-color: #fff;
    padding: 30px 24px 10px 24px;
    letter-spacing: -0.04em;
    border-bottom: 1px solid var(--grey-200);
    font-weight: 500;

    .dropdown {
      padding: 0 6px;
      cursor: pointer;
    }

    svg {
      cursor: pointer;
      rotate: 90deg;
      font-size: 16px;
      width: 24px;
      height: 24px;

      & path {
        fill: #000;
      }
    }
  }

  .status {
    span {
      color: var(--grey-400);
      text-transform: capitalize;
    }
  }

  .user-options {
    cursor: pointer;
    display: grid;
    place-content: center;
    padding: 4px;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .chat-area {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 69px);
    position: sticky;
    top: 69px;
  }

  .main-chat {
    padding: 20px 16px 16px;
    position: sticky;
    top: 69px;
    flex-grow: 1;
    overflow-y: auto;
  }

  .message {
    margin-bottom: 16px;

    .box {
      max-width: 408px;
      padding: 12px 16px 12px 12px;
      margin-bottom: 2px;
      border-radius: 6px 0px 6px 6px;
      letter-spacing: -0.03em;

      p {
        white-space: pre-wrap;
      }
    }

    .media {
      max-width: 408px;
      border-radius: 6px;

      &.media__rounded img {
        border-radius: 6px;
      }

      .vp-video {
        width: 408px;
      }

      img {
        border-radius: 6px 6px 0 0;
      }

      p {
        padding: 12.5px 10px;
        white-space: pre-wrap;
        letter-spacing: -0.03em;
      }
    }

    .document {
      max-width: 408px;
      padding: 12px 16px 12px 12px;
      border-radius: 6px;

      .doc-name {
        flex-grow: 1;
      }

      &.incoming > * {
        color: var(--grey-800);
        margin-bottom: 0;
        letter-spacing: -0.02em;
      }

      & > * {
        color: #ffffff;
        margin-bottom: 0;
        letter-spacing: -0.02em;
      }
    }

    .dot {
      width: 3px;
      height: 3px;
      border-radius: 50%;
      background-color: var(--grey-400);
    }

    .msg-status path {
      stroke: #1c8af0;
    }

    span {
      letter-spacing: -0.02em;
    }
  }

  .notification {
    margin: 8px 20px 20px;

    p {
      font-size: 0.625rem;
      line-height: 0.75rem;
      margin-bottom: 0;
      text-align: center;
      color: var(--grey-600);
      letter-spacing: 0.19em;

      & .time {
        color: var(--grey-900);
      }
    }
  }

  .incoming {
    background: var(--grey-50);
    border: 1px solid rgba(25, 39, 51, 0.05);
  }

  .outgoing {
    background: var(--primary-500);
    position: relative;

    &:hover .dots {
      display: block;
    }

    & > p {
      color: #ffffff !important;
    }

    &__img {
      border: 1px solid rgba(25, 39, 51, 0.5);
    }
  }

  .dots {
    position: absolute;
    bottom: 8px;
    right: 8px;
    cursor: pointer;
    display: none;
  }

  .textarea {
    & textarea {
      height: 84px;
      border: none;
      padding: 12px 24px 0;
      border-radius: 0;
      box-shadow: none;
      resize: none;
      border-top: 1px solid var(--primary-200);

      &:focus-visible:not(.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input, .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover) {
        border: none;
        border-top: 1px solid var(--primary-500);
      }
    }
  }

  .emoji-picker,
  .quick-replies {
    position: absolute;
    left: 8px;
    bottom: 141px;
  }

  .quick-replies {
    width: 100%;
  }

  .options {
    margin: 0 24px;
    padding-bottom: 16px;
    background: #fff;
    width: calc(100% - 48px);
    /* position: absolute;
    bottom: 0; */

    .rel {
      position: relative;
    }

    & svg {
      cursor: pointer;
    }
  }
`;

export default MainConversationStyles;
