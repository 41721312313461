import { BusinessInfo } from 'src/types/user';
import gatewayInstance from './axiosGateway';

type UpdateUser = {
  firstname: string;
  lastname: string;
  email: string;
  profilePicture: string;
};

export const getUser = async (id: number) => {
  const { data } = await gatewayInstance.get(`/user/me/${id}`);
  return data;
};

export const updateUserProfile = async function (payload: UpdateUser) {
  const { data } = await gatewayInstance.put('/users/update/me', payload);

  return data;
};

export const updateBusinessInfo = async function (payload: BusinessInfo) {
  const { data } = await gatewayInstance.put('/business/update', payload);

  return data;
};

export const updatePassword = async function (newPassword: string) {
  const { data } = await gatewayInstance.put('/users/update/password', {
    password: newPassword
  });

  return data;
};
