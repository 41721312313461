import gatewayInstance from './axiosGateway';

export type CustomersPayload = {
  perPage?: number;
  page?: number;
  queryString: string;
  channel?: string;
};

export const getAllCustomers = async (payload: CustomersPayload, businessId: number) => {
  const { data } = await gatewayInstance.get(`/customers/${businessId}`, {
    params: payload
  });

  return data;
};

export const deleteCustomer = async (payload: { id: string; businessId: string }) => {
  const { data } = await gatewayInstance.delete(`/customers/delete`, {
    data: {
      id: payload.id,
      businessId: payload.businessId
    }
  });

  return data;
};

export const updateCustomer = async (id: string, name: string) => {
  const { data } = await gatewayInstance.put(`/customers/update`, {
    customerId: id,
    fullname: name
  });

  return data;
};
