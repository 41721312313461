import React, { useRef, useEffect } from 'react';
import { Navigate, NavLink } from 'react-router-dom';
import { Tooltip } from 'antd';
import SidebarNav from './SidebarNav';
import FlexibleDiv from 'src/components/FlexableDiv';
import { useAuth } from 'src/context/Auth';
import {
  Logo,
  Conversation,
  Settings,
  Signout,
  Customers,
  Templates,
  Dashboard
} from 'src/assets/svg';

type AuthenticatedLayoutProps = {
  children: React.ReactNode;
};

const AuthenticatedLayout = ({ children }: AuthenticatedLayoutProps) => {
  const userContext = useAuth();

  const timer = useRef<ReturnType<typeof setTimeout> | null>(null);

  const resetTimer = () => {
    if (timer.current) {
      clearTimeout(timer.current);
    }

    timer.current = setTimeout(() => {
      userContext?.signout();
    }, 3600000);
  };

  useEffect(() => {
    timer.current = setTimeout(() => {
      userContext?.signout();
    }, 3600000);

    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, []);

  const Sidebar = [{ path: '/dashboard', name: 'Dashboard', Icon: Dashboard }];
  if (userContext?.user?.rolePermissions?.can_view_conversations) {
    Sidebar.push({ path: '/conversations', name: 'Conversation', Icon: Conversation });
  }
  if (userContext?.user?.rolePermissions?.can_view_customers) {
    Sidebar.push({ path: '/customers', name: 'Customers', Icon: Customers });
  }

  Sidebar.push({ path: '/broadcast', name: 'Broadcast', Icon: Templates });
  Sidebar.push({ path: '/settings', name: 'Settings', Icon: Settings });

  // if (
  //   userContext?.user?.rolePermissions?.can_view_roles ||
  //   userContext?.user?.rolePermissions?.can_view_team_members
  // ) {
  // }

  // const Sidebar = [
  //   // { path: "/dashboard", name: "Dashboard", Icon: Dashboard },
  //   { path: '/conversations', name: 'Conversation', Icon: Conversation },
  //   {
  //     path: '/customers',
  //     name: 'Customers',
  //     Icon: Customers
  //   },
  //   // { path: "/templates", name: "Templates", Icon: Templates },
  //   // { path: "/products", name: "Products", Icon: Products },
  //   // { path: "/earnings", name: "Earnings", Icon: Earnings },
  //   { path: '/settings', name: 'Settings', Icon: Settings }
  // ];

  if (localStorage.getItem('VP_TOKEN') === null || !userContext?.user) {
    userContext?.signout();
    return <Navigate to="/signin" />;
  }

  return (
    <section onMouseMove={resetTimer} onKeyDown={resetTimer}>
      <FlexibleDiv>
        <SidebarNav>
          <FlexibleDiv mb="32px" justifyContent="center">
            <Logo />
          </FlexibleDiv>
          <FlexibleDiv
            className="nav"
            flexDir="column"
            justifyContent="space-between"
            alignItems="center">
            <FlexibleDiv flexDir="column" flexGap="12px">
              {Sidebar.map(({ name, path, Icon }: any) => (
                <Tooltip key={name} title={name} placement="right">
                  <NavLink to={path} className="nav-links">
                    {({ isActive }) => (
                      <div className={isActive ? 'active' : ''}>
                        <Icon />
                      </div>
                    )}
                  </NavLink>
                </Tooltip>
              ))}
            </FlexibleDiv>
            <FlexibleDiv flexDir="column" flexGap="16px" alignItems="center">
              <Tooltip title="Signout" placement="right">
                <div className="nav-links" onClick={() => userContext.signout()}>
                  <Signout />
                </div>
              </Tooltip>
              <div className="avatar">
                <img
                  alt="Business Logo"
                  src={userContext.user?.businessLogo || 'https://via.placeholder.com/36/36'}
                />
              </div>
            </FlexibleDiv>
          </FlexibleDiv>
        </SidebarNav>
        {children}
      </FlexibleDiv>
    </section>
  );
};

export default AuthenticatedLayout;
