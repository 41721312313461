import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Player } from 'video-react';
import { Dropdown, Menu, Tooltip, Upload } from 'antd';
import { DateTime } from 'luxon';
import Picker, { SKIN_TONE_MEDIUM } from 'emoji-picker-react';
import type { UploadProps } from 'antd';
import FlexibleDiv from 'src/components/FlexableDiv';
import ConversationsSidebar from '../ConversationsSidebar';
import { Text } from 'src/components/Typography';
import { FormInput } from '../Form';
import FormTextArea from '../Form/TextArea';
import ChatQuickReplies from '../ChatQuickReplies';
import Button from '../Button';
import VPDrawer from 'src/components/VPDrawer';
import { SocketContext } from 'src/context/socketContext';
import { useAuth } from 'src/context/Auth';
import VPModal from '../VPModal';
import AssignConvoModal from '../AssignConvoModal';
import { assignConvoToAgent } from 'src/network/conversations';
import toastError from 'src/utils/toastError';
import toast from 'src/utils/toasts';
import { ISingleConvo } from 'src/types';
import { IAggregatedConversation } from 'src/types/conversation';
import {
  Attach,
  MenuIcon,
  MsgStatus,
  Smiley,
  Trash,
  QuickReply,
  File,
  DotsVertical
} from 'src/assets/svg';
import MainConversationStyles from './styled';
import 'video-react/dist/video-react.css';

type MainConvProps = {
  activeChat: ISingleConvo;
  showCustomerDetails: boolean;
  setAllConversations: React.Dispatch<React.SetStateAction<IAggregatedConversation[]>>;
  setShowCustomerDetails: (val: boolean) => void;
};

const MainConversation = ({
  activeChat,
  setAllConversations,
  showCustomerDetails,
  setShowCustomerDetails
}: MainConvProps) => {
  const chatRef = useRef<HTMLDivElement>(null);
  const emojiRef = useRef<HTMLDivElement>(null);

  const [message, setMessage] = useState('');
  const [showEmoji, setShowEmoji] = useState(false);
  const [showQuickReplies, setShowQuickReplies] = useState(false);
  const [showAssignConversationModal, setShowAssignConversationModal] = useState(false);
  const [showCloseConversationModal, setShowCloseConversationModal] = useState(false);

  const [textareaDisable, setTextareaDisable] = useState(false);
  const [documentName, setDocumentName] = useState('');
  const [attachmentURL, setAttachmentURL] = useState({ type: '', url: '' });
  const [msgType, setMsgType] = useState<'TEXT' | 'IMAGE' | 'VIDEO' | 'DOCUMENT'>('TEXT');

  const { socket } = useContext(SocketContext);
  const userContext = useAuth();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (emojiRef.current && !emojiRef.current.contains(event.target as Node)) {
        setShowEmoji(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    socket.emit('joinConversation', { conversationId: activeChat.conversation.id });

    return () => {
      socket.off('joinConversation');
    };
  }, [activeChat]);

  const deleteMsgMenu = (msg: any) => (
    <Menu
      className="dropdown-menu delete-msg-menu"
      items={[
        {
          label: 'Delete message',
          key: 'delete',
          onClick: async () => {
            const payload = {
              conversationId: msg.conversationId,
              messageId: msg.id
            };
            socket.emit('MESSAGE_DELETE_EVENT', payload);
          },
          icon: <Trash className="error-trash" />
        }
      ]}
    />
  );

  const menu = (
    <Menu
      className="dropdown-menu"
      items={[
        userContext?.user?.rolePermissions.can_assign_chats_to_self
          ? {
              label: 'Assign conversation to me',
              key: 'assignToMe',
              onClick: async () => {
                try {
                  const res = await assignConvoToAgent({
                    agentId: userContext?.user?.id!,
                    conversationId: activeChat.messages[0].conversationId
                  });
                  socket.emit('assignAgentToConversation', {
                    conversationId: activeChat.messages[0].conversationId,
                    agentId: userContext?.user?.id!
                  });
                  toast('success', 'Conversation assigned to you');
                } catch (err) {
                  toastError(err);
                }
              }
            }
          : null,
        userContext?.user?.rolePermissions.can_assign_chats_to_others
          ? {
              label: 'Assign Conversation',
              key: 'assign',
              onClick: () => setShowAssignConversationModal(true)
            }
          : null,
        {
          label: 'Close conversation',
          key: 'close',
          onClick: () => setShowCloseConversationModal(true)
        }
      ]}
    />
  );

  const handleSendMessage = () => {
    const conversationId = activeChat.messages[0].conversationId;
    if (showEmoji) {
      setShowEmoji(false);
    }
    const payload: {
      attachment?: string;
      attachmentType?: string;
      conversationId: string;
      messageType: string;
      messageBody: string;
      direction: string;
      businessId: string | number | undefined;
      agentId?: number;
    } = {
      conversationId,
      messageType: msgType,
      messageBody: message,
      direction: 'OUTBOUND',
      agentId: userContext?.user?.id,
      businessId: userContext?.user?.businessId || userContext?.user?.id
    };

    if (msgType !== 'TEXT' && attachmentURL) {
      payload['attachment'] = attachmentURL.url;
      payload['attachmentType'] = attachmentURL.type;
    }

    socket.emit('NEW_MESSAGE_EVENT:OUTBOUND', payload);
    setTextareaDisable(false);
    setMsgType('TEXT');
    setMessage('');
    setDocumentName('');
    setAttachmentURL({ type: '', url: '' });
  };

  useEffect(() => {
    const messages = document.querySelectorAll('.main-chat > div');

    let timeout = setTimeout(() => {
      messages[messages.length - 1].scrollIntoView({ behavior: 'smooth' });
    }, 200);

    return () => {
      clearTimeout(timeout);
    };
  }, [activeChat.messages]);

  useEffect(() => {
    if (activeChat.messages.length > 0) {
      const conversationId = activeChat.messages[0].conversationId;
      socket.emit('MARK_CONVERSATION_AS_READ_EVENT', {
        conversationId
      });
    }
  }, [activeChat.messages, socket]);

  const attachUploadProps: UploadProps = {
    name: 'file',
    multiple: false,
    maxCount: 1,
    accept:
      'image/*,video/*,text/csv,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,text/plain,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    showUploadList: false,
    action: `${process.env.REACT_APP_VIPI_API_URL}media/create/${
      userContext?.user?.businessId || userContext?.user?.id
    }`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('VP_TOKEN')}`
    },
    onChange(info) {
      const { status } = info.file;
      if (status === 'uploading') {
        if (info.file.type?.includes('image')) {
          setMsgType('IMAGE');
        } else if (info.file.type?.includes('video')) {
          setMsgType('VIDEO');
        } else {
          setMsgType('DOCUMENT');
        }

        if (info.file.type?.includes('image') || info.file.type?.includes('video')) {
          setTextareaDisable(false);
        } else {
          setTextareaDisable(true);
        }
      }
      if (status === 'done') {
        setDocumentName(info.file.name);
        toast('success', `${info.file.name} file uploaded successfully.`);
        setAttachmentURL({ type: info.file.type || '', url: info.file.response });
      } else if (status === 'error') {
        toast('error', `${info.file.name} file upload failed.`);
        setTextareaDisable(false);
      }
    },
    onRemove() {
      setTextareaDisable(false);
      setMsgType('TEXT');
    }
  };

  return (
    <MainConversationStyles>
      <main>
        <FlexibleDiv className="header" justifyContent="space-between" alignItems="center">
          <FlexibleDiv flexGap="10px" alignItems="center">
            <Text variant="lg" mb="0">
              {activeChat.conversation?.customerId?.fullName}
            </Text>
            {/* <FlexibleDiv flexGap="6px" alignItems="center" className="status">
              <Status status={activeChat.conversation?.lastMessage.status} />
              <Text as="span" variant="sm">
                {activeChat.conversation?.lastMessage?.status}
              </Text>
            </FlexibleDiv> */}
          </FlexibleDiv>
          <Dropdown
            overlay={menu}
            trigger={['click']}
            placement="bottomRight"
            className="user-options">
            <div>
              <MenuIcon />
            </div>
          </Dropdown>
        </FlexibleDiv>
        <div className="chat-area">
          <div className="main-chat" ref={chatRef}>
            {activeChat.messages.map((msg) => {
              // if (msg.type === 'notification') {
              //   return (
              //     <div className="notification">
              //       <Text variant="xs">
              //         <span className="time">{msg}</span> {msg.message}
              //       </Text>
              //     </div>
              //   );
              // }
              if (msg.direction === 'INBOUND') {
                if (msg.status === 'DELETED') {
                  return (
                    <div className="message" key={msg.messageId}>
                      <div className="incoming box">
                        <Text variant="sm" mb="0" className="deleted">
                          This message has been deleted
                        </Text>
                      </div>
                      <Text as="span" variant="xs">
                        {DateTime.fromISO(String(msg.createdAt)).toLocaleString(
                          DateTime.DATETIME_MED
                        )}
                      </Text>
                    </div>
                  );
                }
                if (msg.messageType === 'IMAGE') {
                  return (
                    <div className="message" key={msg.messageId}>
                      <div className={`incoming media ${!msg.messageBody && 'media__rounded'}`}>
                        <img
                          src={msg.attachments && msg.attachments}
                          alt="message"
                          width={'100%'}
                        />
                        {msg.messageBody && (
                          <Text variant="sm" mb="0">
                            {msg.messageBody}
                          </Text>
                        )}
                      </div>
                      <Text as="span" variant="xs">
                        {DateTime.fromISO(String(msg.createdAt)).toLocaleString(
                          DateTime.DATETIME_MED
                        )}
                      </Text>
                    </div>
                  );
                }
                if (msg.messageType === 'VIDEO') {
                  return (
                    <div className="message" key={msg.messageId}>
                      <div className="outgoing media">
                        <div className="vp-video">
                          <Player aspectRatio="auto">
                            <source src={msg.attachments && msg.attachments} />
                          </Player>
                        </div>
                        {msg.messageBody && (
                          <Text variant="sm" mb="0">
                            {msg.messageBody}
                          </Text>
                        )}
                      </div>
                      <Text as="span" variant="xs">
                        {DateTime.fromISO(String(msg.createdAt)).toLocaleString(
                          DateTime.DATETIME_MED
                        )}
                      </Text>
                    </div>
                  );
                }
                if (msg.messageType === 'DOCUMENT') {
                  return (
                    <div className="message">
                      <FlexibleDiv flexGap="10px" alignItems="center" className="incoming document">
                        <File />
                        <Text variant="md" className="doc-name">
                          {decodeURI(
                            msg.attachments &&
                              msg.attachments.split('/').slice(-1)[0].split('.').slice(-2).join('.')
                          )}
                        </Text>
                        <Text
                          as="a"
                          download
                          target="_blank"
                          href={msg.attachments && msg.attachments}
                          variant="xs">
                          Open
                        </Text>
                      </FlexibleDiv>
                      <Text as="span" variant="xs">
                        {DateTime.fromISO(String(msg.createdAt)).toLocaleString(
                          DateTime.DATETIME_MED
                        )}
                      </Text>
                    </div>
                  );
                }
                return (
                  <div className="message" key={msg.messageId}>
                    <div className="incoming box">
                      <Text variant="sm" mb="0">
                        {msg.messageBody}
                      </Text>
                    </div>
                    <Text as="span" variant="xs">
                      {DateTime.fromISO(String(msg.createdAt)).toLocaleString(
                        DateTime.DATETIME_MED
                      )}
                    </Text>
                  </div>
                );
              }
              if (msg.direction === 'OUTBOUND') {
                if (msg.status === 'DELETED') {
                  return (
                    <FlexibleDiv justifyContent="flex-end" key={msg.messageId}>
                      <div className="message">
                        <div className="outgoing box">
                          <Text variant="sm" mb="0" className="deleted outbound">
                            This message has been deleted
                          </Text>
                        </div>
                        <FlexibleDiv justifyContent="flex-end">
                          <FlexibleDiv flexGap="6px" alignItems="center">
                            <MsgStatus className="" />
                            {/* <MsgStatus className={msg.status ? 'msg-status' : ''} /> */}
                            <span className="dot"></span>
                            <Text as="span" variant="xs">
                              {DateTime.fromISO(String(msg.createdAt)).toLocaleString(
                                DateTime.DATETIME_MED
                              )}
                            </Text>
                          </FlexibleDiv>
                        </FlexibleDiv>
                      </div>
                    </FlexibleDiv>
                  );
                }
                if (msg.messageType === 'IMAGE' || msg.messageType === 'ATTACHMENT') {
                  return (
                    <FlexibleDiv justifyContent="flex-end" key={msg.messageId}>
                      <div className="message">
                        <div className={`outgoing media ${!msg.messageBody && 'media__rounded'}`}>
                          <img
                            src={msg.attachments && msg.attachments}
                            alt="message"
                            width={'100%'}
                          />
                          {msg.messageBody && (
                            <Text variant="sm" mb="0">
                              {msg.messageBody}
                            </Text>
                          )}
                        </div>
                        <FlexibleDiv justifyContent="flex-end">
                          <FlexibleDiv flexGap="6px" alignItems="center">
                            <MsgStatus className={msg.status ? 'msg-status' : ''} />
                            <span className="dot"></span>
                            <Text as="span" variant="xs">
                              {DateTime.fromISO(String(msg.createdAt)).toLocaleString(
                                DateTime.DATETIME_MED
                              )}
                            </Text>
                          </FlexibleDiv>
                        </FlexibleDiv>
                      </div>
                    </FlexibleDiv>
                  );
                }
                if (msg.messageType === 'VIDEO') {
                  return (
                    <FlexibleDiv justifyContent="flex-end" key={msg.messageId}>
                      <div className="message">
                        <div className="outgoing media">
                          <div className="vp-video">
                            <Player aspectRatio="auto">
                              <source src={msg.attachments && msg.attachments[0]} />
                            </Player>
                          </div>
                          {msg.messageBody && (
                            <Text variant="sm" mb="0">
                              {msg.messageBody}
                            </Text>
                          )}
                        </div>
                        <FlexibleDiv justifyContent="flex-end">
                          <FlexibleDiv flexGap="6px" alignItems="center">
                            <MsgStatus className={msg.status ? 'msg-status' : ''} />
                            <span className="dot"></span>
                            <Text as="span" variant="xs">
                              {DateTime.fromISO(String(msg.createdAt)).toLocaleString(
                                DateTime.DATETIME_MED
                              )}
                            </Text>
                          </FlexibleDiv>
                        </FlexibleDiv>
                      </div>
                    </FlexibleDiv>
                  );
                }
                if (msg.messageType === 'DOCUMENT') {
                  return (
                    <FlexibleDiv justifyContent="flex-end" key={msg.messageId}>
                      <div className="message">
                        <FlexibleDiv
                          flexGap="10px"
                          alignItems="center"
                          className="outgoing document">
                          <File />
                          <Text variant="md" className="doc-name">
                            {decodeURI(
                              msg.attachments &&
                                msg.attachments
                                  .split('/')
                                  .slice(-1)[0]
                                  .split('.')
                                  .slice(-2)
                                  .join('.')
                            )}
                          </Text>
                          <Text
                            as="a"
                            download
                            target="_blank"
                            href={msg.attachments && msg.attachments[0]}
                            variant="xs">
                            Open
                          </Text>
                        </FlexibleDiv>
                        <FlexibleDiv justifyContent="flex-end">
                          <FlexibleDiv flexGap="6px" alignItems="center">
                            <MsgStatus className={msg.status ? 'msg-status' : ''} />
                            <span className="dot"></span>
                            <Text as="span" variant="xs">
                              {DateTime.fromISO(String(msg.createdAt)).toLocaleString(
                                DateTime.DATETIME_MED
                              )}
                            </Text>
                          </FlexibleDiv>
                        </FlexibleDiv>
                      </div>
                    </FlexibleDiv>
                  );
                }
                return (
                  <FlexibleDiv justifyContent="flex-end" key={msg.messageId}>
                    <div className="message">
                      <div className="outgoing box">
                        <Text variant="sm" mb="0">
                          {msg.messageBody}
                        </Text>
                        {activeChat.conversation.conversationChannel === 'INSTAGRAM' && (
                          <Dropdown
                            overlay={deleteMsgMenu(msg)}
                            trigger={['click']}
                            placement="bottomRight">
                            <DotsVertical className="dots" />
                          </Dropdown>
                        )}
                      </div>
                      <FlexibleDiv justifyContent="flex-end">
                        <FlexibleDiv flexGap="6px" alignItems="center">
                          <MsgStatus className={msg.status ? 'msg-status' : ''} />
                          <span className="dot"></span>
                          <Text as="span" variant="xs">
                            {DateTime.fromISO(String(msg.createdAt)).toLocaleString(
                              DateTime.DATETIME_MED
                            )}
                          </Text>
                        </FlexibleDiv>
                      </FlexibleDiv>
                    </div>
                  </FlexibleDiv>
                );
              }
            })}
          </div>
          {activeChat.conversation.conversationState === 'OPEN' &&
          userContext?.user?.rolePermissions?.can_chat_and_share_files_with_customers ? (
            <div className="textarea">
              <div>
                {attachmentURL.url && attachmentURL.type.includes('image') && (
                  <div className="img-upload">
                    <img src={attachmentURL.url} alt="" width="128px" />
                    <span onClick={() => setAttachmentURL({ type: '', url: '' })}>
                      <Trash width={'12px'} />
                    </span>
                  </div>
                )}
                {attachmentURL.url && attachmentURL.type.includes('video') && (
                  <div className="video-upload">
                    <Player>
                      <source src={attachmentURL.url} />
                    </Player>
                    <span onClick={() => setAttachmentURL({ type: '', url: '' })}>
                      <Trash width={'12px'} />
                    </span>
                  </div>
                )}
                {attachmentURL.url &&
                  !(
                    attachmentURL.type.includes('video') || attachmentURL.type.includes('image')
                  ) && (
                    <div className="doc-upload">
                      <FlexibleDiv flexGap="12px" alignItems="center">
                        <Text
                          as="a"
                          href={attachmentURL.url}
                          target="_blank"
                          variant="sm"
                          weight="medium"
                          mb="0">
                          {documentName}
                        </Text>
                      </FlexibleDiv>
                      <span onClick={() => setAttachmentURL({ type: '', url: '' })}>
                        <Trash width={'12px'} />
                      </span>
                    </div>
                  )}
                <form>
                  <FormInput
                    disabled={textareaDisable}
                    as={FormTextArea}
                    value={message}
                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                      setMessage(e.currentTarget.value)
                    }
                    onKeyDown={(e: React.KeyboardEvent<HTMLTextAreaElement>) => {
                      if (e.key === 'Enter' && !e.shiftKey) {
                        handleSendMessage();
                      }
                    }}
                  />
                </form>
                {showEmoji && (
                  <div ref={emojiRef} className="emoji-picker">
                    <Picker
                      native
                      disableSearchBar
                      skinTone={SKIN_TONE_MEDIUM}
                      onEmojiClick={(event, emojiObject) =>
                        setMessage((msg) => msg + emojiObject.emoji)
                      }
                    />
                  </div>
                )}
                <div className="options">
                  <FlexibleDiv flexGap="24px" alignItems="center" justifyContent="space-between">
                    <FlexibleDiv flexGap="16px" alignItems="center" className="rel">
                      {/* <Tooltip title="Generate payment link" placement="top">
                      <GeneratePayment />
                    </Tooltip> */}
                      <Tooltip title="Emojis" placement="top">
                        <Smiley onClick={() => setShowEmoji((prev) => !prev)} />
                      </Tooltip>
                      <Tooltip title="Attachment" placement="top">
                        <Upload {...attachUploadProps}>
                          <Attach />
                        </Upload>
                      </Tooltip>
                      <Tooltip title="Insert Quick Reply" placement="top">
                        <QuickReply onClick={() => setShowQuickReplies(true)} />
                      </Tooltip>
                    </FlexibleDiv>
                    <Button
                      sm
                      onClick={() => {
                        if (message.trim() !== '' || msgType !== 'TEXT') {
                          handleSendMessage();
                        }
                      }}>
                      Send
                    </Button>
                  </FlexibleDiv>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </main>
      {showCustomerDetails && (
        <ConversationsSidebar
          setShowCustomerDetails={setShowCustomerDetails}
          activeChat={activeChat.conversation}
        />
      )}
      <VPModal
        centered
        closable={false}
        className="form-modal"
        visible={showCloseConversationModal}
        footer={<></>}>
        <Text as="h1" mb="8px" variant="lg" weight="medium">
          Close conversation
        </Text>
        <Text variant="md" color="var(--grey-500)" mb="28px">
          Are you sure you want to close this conversation?
        </Text>
        <FlexibleDiv flexGap="12px" alignItems="center">
          <button className="btn cancel" onClick={() => setShowCloseConversationModal(false)}>
            Cancel
          </button>
          <button
            className="btn primary"
            onClick={() => {
              socket.emit('MARK_CONVERSATION_AS_CLOSED', {
                conversationId: activeChat.messages[0].conversationId
              });
              toast('success', 'Conversation closed successfully');
              setShowCloseConversationModal(false);
            }}>
            Yes, close
          </button>
        </FlexibleDiv>
      </VPModal>
      <VPModal
        centered
        closable={false}
        className="form-modal"
        visible={showAssignConversationModal}
        footer={<></>}>
        <AssignConvoModal
          conversationId={activeChat.messages[0].conversationId}
          setShowAssignConversationModal={setShowAssignConversationModal}
        />
      </VPModal>
      <VPDrawer visible={showQuickReplies} onClose={() => setShowQuickReplies(false)}>
        <ChatQuickReplies
          onClose={() => setShowQuickReplies(false)}
          onReplyClick={(reply) => {
            setMessage((msg) => msg + reply);
            setShowQuickReplies(false);
          }}
        />
      </VPDrawer>
    </MainConversationStyles>
  );
};

const Status = styled.div<{ status: string }>`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: ${({ status }) =>
    status === 'online'
      ? 'var(--success-500)'
      : status === 'away'
      ? 'var(--warning-500)'
      : 'var(--grey-400)'};
`;

export default React.memo(MainConversation);
