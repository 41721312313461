import { useState } from 'react';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { Form } from 'antd';
import Button from 'src/components/Button';
import AuthScreensLayout from 'src/layout/AuthScreensLayout';
import { resetPassword } from 'src/network/auth';
import toast from 'src/utils/toasts';
import { toastErrorUnauthScreens } from 'src/utils/toastError';
import { Display, Text } from 'src/components/Typography';
import { FormItem, FormInput } from 'src/components/Form';
import { ResetPassword } from 'src/types/auth';

const ResetPasswordPage = () => {
  const navigate = useNavigate();
  const [onSuccessReset, setOnSuccessReset] = useState(false);
  let [searchParams, setSearchParams] = useSearchParams();

  const onFinish = async (values: ResetPassword) => {
    const { newPassword } = values;
    const token = searchParams.get('passwordResetToken') as string;

    try {
      const res = await resetPassword({ newPassword }, token);
      toast('success', 'Your password has been changed successfully');
      setTimeout(() => navigate('/signin'), 3000);
    } catch (error) {
      toastErrorUnauthScreens(error);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  if (!searchParams.get('passwordResetToken')) {
    return <Navigate to="/signin" replace />;
  }

  return (
    <AuthScreensLayout>
      <Display as="h1" variant="md" weight="bold">
        {onSuccessReset ? 'Password reset successful' : 'Set a new password'}
      </Display>
      <Text variant="lg" color="#667085" mb="36px">
        {onSuccessReset
          ? 'Your password has been successfully reset. Log in with the new password to use Vipichat'
          : 'Enter a new password for your Vipichat account'}
      </Text>
      {onSuccessReset ? (
        <>
          <Button fullWidth onClick={() => navigate('../signin')}>
            Log in
          </Button>
        </>
      ) : (
        <Form
          name="resetPassword"
          autoComplete="off"
          layout="vertical"
          requiredMark={false}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          validateTrigger={['onBlur', 'onChange']}
        >
          <FormItem
            mb="36px"
            name="newPassword"
            label={
              <Text as="span" variant="sm" weight="medium" color="var(--grey-700)">
                New password
              </Text>
            }
            rules={[
              { required: true, message: 'This field is required' },
              { min: 8, message: 'Password must be at least 8 characters' }
            ]}
          >
            <FormInput width="100%" type="password" />
          </FormItem>
          <FormItem
            mb="36px"
            name="confirmPassword"
            label={
              <Text as="span" variant="sm" weight="medium" color="var(--grey-700)">
                Confirm password
              </Text>
            }
            rules={[
              { required: true, message: 'This field is required' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('newPassword') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Passwords do not match'));
                }
              })
            ]}
          >
            <FormInput width="100%" type="password" />
          </FormItem>
          <Button htmlType="submit" fullWidth mb="28px">
            Confirm password
          </Button>
        </Form>
      )}
    </AuthScreensLayout>
  );
};

export default ResetPasswordPage;
