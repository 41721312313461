import React, { useState } from 'react';
import { flushSync } from 'react-dom';
import { closePaymentModal, useFlutterwave } from 'flutterwave-react-v3';
import { Form } from 'antd';
import Button from 'src/components/Button';
import { FormInput, FormItem } from 'src/components/Form';
import { Text } from 'src/components/Typography';
import FlexibleDiv from '../FlexableDiv';
import { initTopUp } from 'src/network/wallet';
import { useAuth } from 'src/context/Auth';
import toastError from 'src/utils/toastError';
import { Chevron } from 'src/assets/svg';
import { currencyFormatter } from 'src/utils/app';
import { VipiLogo } from 'src/constants';

type TopUpDrawerProps = {
  setActiveDrawer: (val: string) => void;
};

const TopUpWalletDrawer = ({ setActiveDrawer }: TopUpDrawerProps) => {
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState('');
  const [flwTxRef, setFlwTxRef] = useState(
    `INV-${Math.floor(Date.now() * Math.random()).toString()}`
  );

  const userContext = useAuth();
  const [form] = Form.useForm();

  const flutterwaveConfig = {
    amount: parseFloat(amount),
    public_key: process.env.REACT_APP_FLW_KEY as string,
    tx_ref: flwTxRef,
    meta: {
      business_id: process.env.REACT_APP_VIPI_BUSINESS_ID
    },
    currency: 'NGN',
    payment_options: 'card,mobilemoney,ussd',
    customer: {
      email: userContext?.user?.email!,
      phone_number: '',
      name: `${userContext?.user?.firstName} ${userContext?.user?.lastName}`
    },
    customizations: {
      title: 'Vipichat',
      description: 'Vipichat wallet top-up',
      logo: VipiLogo
    }
  };

  const handleFlutterPayment = useFlutterwave(flutterwaveConfig);

  const onFinish = async (values: any) => {
    try {
      setLoading(true);
      const res = await initTopUp(parseFloat(amount), flwTxRef);
      handleFlutterPayment({
        callback: (response) => {
          closePaymentModal();
          setLoading(false);
          form.resetFields();

          flushSync(() => {
            // @ts-ignore
            wallet?.setWalletDetails((prev) => {
              if (prev) {
                return { ...prev, walletBalance: prev.walletBalance + parseFloat(amount) };
              }
            });
            setAmount('');
            setActiveDrawer('broadcastSummary');
          });
        },
        onClose: () => {
          setLoading(false);
        }
      });
    } catch (err) {
      toastError(err);
    }
  };

  return (
    <div>
      <div className="header">
        <Text
          mb="0"
          variant="sm"
          className="back"
          onClick={() => {
            form.resetFields();
            setActiveDrawer('broadcastSummary');
          }}>
          <Chevron />
          Back
        </Text>
        <Text variant="md" className="title" mb="0">
          Top-up wallet
        </Text>
      </div>
      <Form
        form={form}
        name="topUpWallet"
        autoComplete="off"
        layout="vertical"
        requiredMark={false}
        onFinish={onFinish}>
        <FlexibleDiv
          flexDir="column"
          justifyContent="space-between"
          className="main-area select-contacts">
          <section className="upper">
            <FormItem
              mb="6px"
              name="amount"
              label={
                <Text as="span" variant="sm" weight="medium" color="var(--grey-700)">
                  Top-up amount
                </Text>
              }
              rules={[
                {
                  required: true,
                  message: 'This field is required'
                },
                () => ({
                  validator(_, value) {
                    if (!value) {
                      return Promise.reject();
                    }
                    if (isNaN(value)) {
                      return Promise.reject('Invalid amount');
                    }
                    if (!value.match(/\d{5,}/)) {
                      return Promise.reject(
                        `Amount can't be less than ${currencyFormatter.format(10000)}`
                      );
                    }
                    return Promise.resolve();
                  }
                })
              ]}>
              <div className="amount-input">
                <div className="currency">NGN</div>
                <FormInput
                  className="inputField"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                />
              </div>
            </FormItem>
          </section>
          <Button className="btn-con" fullWidth htmlType="submit" loading={loading}>
            Continue
          </Button>
        </FlexibleDiv>
      </Form>
    </div>
  );
};

export default TopUpWalletDrawer;
