import { BusinessInfo, User } from './user';

export interface IMessage {
  conversationId: string;
  messageBody: string;
  messageType: string;
  attachmentType?: string;
  attachment?: Array<any>;
  attachments?: any;
  senderId: string;
  sentBy: string;
  status: string;
  messageId: string;
  direction: string;
  quickReplies?: string[];
  webMessage: string;
  isTemplate: boolean;
  updatedAt: string;
  createdAt: string;
  id: string;
}

export type conversationsParam = {
  perPage: number;
  page: number;
  channel: 'ALL' | 'FACEBOOK' | 'WHATSAPP' | 'INSTAGRAM';
  range: string;
};

export type newConversationsParam = {
  page: number;
  perPage: number;
  channel?: 'ALL' | 'FACEBOOK' | 'WHATSAPP' | 'INSTAGRAM';
  search?: string;
};

export interface IGetAllConvosResponse {
  conversations: IConversation;
  current: number;
  total: number;
  pages: number;
}

export enum Channels {
  Facebook = 'FACEBOOK',
  Whatsapp = 'WHATSAPP',
  Instagram = 'INSTAGRAM',
  Web = 'WEB'
}

export interface IConversation {
  _id: string;
  businessId: string;
  conversationId: string;
  conversationChannel: Channels;
  conversationHandler: 'BOT' | 'HUMAN';
  conversationAssignedTo: string;
  conversationClosedBy: string;
  conversationHasBeenAssigned: boolean;
  conversationAcceptedOn: Date;
  customerId: string;
  conversationState: 'OPEN' | 'CLOSE';
}

export interface ICustomer {
  id: string;
  fullName: string;
  lastName: string;
  firstName: string;
  profilePicture: string;
  email?: string;
  integrationId: string;
  businessId: string;
  platformIdentifier: string;
  channel: string;
}

export interface IAggregatedConversation {
  read: boolean;
  lastMessage: IMessage;
  customerId: ICustomer;
  conversationAssignedTo?: User;
  business: BusinessInfo;
  conversationState: 'OPEN' | 'CLOSED';
  _id: string;
  id: string | number;
  businessId: string;
  conversationId: string;
  conversationChannel: Channels;
  agent: Omit<AgentWithConvo, '_count'>;
}

export type AgentWithConvo = {
  accountType: string;
  businessId: number | null;
  channel?: string;
  createdAt: string;
  email: string;
  facebookPsid?: string;
  firstName: string;
  id: number;
  isActive: boolean;
  isOnline: boolean;
  lastName: string;
  password?: string;
  passwordResetToken: string | null;
  profilePicture: string | null;
  roleId: number;
  updatedAt: string;
  whatsappNo?: string;
  _count: {
    conversations: 0;
  };
};
